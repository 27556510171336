<template>
  <div class="search">
    <v-container>
      <div class="d-flex align-center flex-row flex-wrap mb-3 mb-sm-0">
        <h1 class="search-title">
          Risultati ricerca per <span class="highlight"> {{ query }}</span>
        </h1>
        <strong
          class="text-subtitle-1 font-weight-bold ml-1 mt-1
          "
          v-if="count > 0"
        >
          ({{ count }} prodotti)
        </strong>
      </div>
      <p class="subtitle-1" v-if="filter">in {{ filter }}</p>
      <p class="subtitle-2" v-if="realSearch">
        Avevi cercato
        <a class="real_search">{{ realSearch }}</a>
        ma non ha prodotto risultati.
      </p>

      <!-- <component
        v-if="headerProposals.length > 0"
        :key="key"
        :is="headerMode"
        :title="headerTitle"
        :proposals="headerProposals"
        paginationClass="pagination-header"
        :cols="1"
        :sm="2"
        :md="2"
        :lg="2"
        :xl="2"
      /> -->

      <ProductListGrid
        :query="query"
        :barcode="barcode"
        :parentCategoryId="parent_category_id"
        :key="key"
        mode="search"
        @productsCount="updateCount"
        @searchedText="updateRealSearch"
      />
    </v-container>
    <!-- <v-container v-if="count == 0">
      <span v-if="promoFilter">
        Non sono presenti prodotti in promozione per la ricerca di
        <strong> {{ query }} </strong>.
      </span>
      <span v-if="!promoFilter">
        La ricerca di <strong>{{ query }}</strong> non ha prodotto risultati.
      </span>
    </v-container> -->
  </div>
</template>
<style scoped lang="scss">
h1 {
  font-size: 28px;
  font-weight: normal;
  .highlight {
    font-weight: bold;
  }
}
.real_search {
  cursor: text;
}
</style>
<script>
import ProductListGrid from "@/components/product/ProductListGrid";
import { mapActions, mapGetters } from "vuex";
import categoryMixins from "~/mixins/category";
import deliveryReactive from "~/mixins/deliveryReactive";
import { mapProposalComponent } from "~/service/ebsn";

export default {
  name: "SearchProducts",
  // props: ["query", "parent_category_id"],
  components: {
    ProductListGrid
    // ,
    // ProposalProductListSlider: () =>
    //   import("@/components/proposal/ProposalProductSlider.vue"),
    // ProposalProductGrid: () =>
    //   import("@/components/proposal/ProposalProductGrid.vue"),
    // ProposalSlider: () => import("@/components/proposal/ProposalSlider.vue"),
    // ProposalGrid: () => import("@/components/proposal/ProposalGrid.vue")
  },
  data() {
    return {
      key: 0,
      count: -1,
      query: null,
      barcode: null,
      parent_category_id: null,
      realSearch: null
    };
  },
  mixins: [categoryMixins, deliveryReactive],
  computed: {
    ...mapGetters({
      promoFilter: "category/promoFilter"
    }),
    ...mapProposalComponent({
      header: "header"
    })
  },
  methods: {
    ...mapActions({
      setCategory: "category/setCategory",
      resetFilters: "category/resetFilters"
    }),
    capitalizeFirstLetter(string) {
      return string && string.length
        ? string.charAt(0).toUpperCase() + string.slice(1)
        : "";
    },
    updateCount(count) {
      this.count = count;
    },
    updateRealSearch(searchedText) {
      this.realSearch = this.query;
      this.query = searchedText;
    },
    getQueryParams() {
      this.query = this.$route.query.q;
      this.barcode = this.$route.query.barcode;
      // this.queryLabel = this.$route.query.barcode
      //   ? this.$route.query.barcode
      //   : this.$route.query.q;
      this.parent_category_id = this.$route.query.parent_category_id;
      this.filter = this.$route.query.filter;
    },
    reload() {
      this.$store.dispatch("category/resetFilters");
      this.key = this.key + 1;
    }
  },
  async created() {
    this.getQueryParams();
    await this.resetFilters("q=" + this.query);
  },
  watch: {
    async "$route.query"() {
      this.getQueryParams();
      this.key = this.key + 1;
      this.realSearch = null;
      this.count = null;
      await this.resetFilters("q=" + this.query);
      await this.setCategory({
        slug: "search",
        query: this.query,
        force: true
      });
    }
  },
  beforeRouteLeave(to, from, next) {
    global.EventBus.$emit("clearSearch");
    next();
  }
};
</script>
